import axios from "axios";
import AbortController from "abort-controller";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

// Determine if we're in development mode
const isDevelopment = process.env.NODE_ENV === "development";

// Choose API URL based on environment
export const API_URL = isDevelopment
  ? process.env.REACT_APP_API_BASE_URL || "http://localhost:8000"
  : process.env.REACT_APP_API_BASE_URL || "https://api.lexgine.com";

const apiConfig = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  paramsSerializer: {
    indexes: null
  }
});

const pendingRequests = new Map();

apiConfig.interceptors.request.use(async config => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user ? user.token : "";
  const controller = new AbortController();
  config.signal = controller.signal;

  const requestKey = `${config.url}&${config.method}`;

  if (pendingRequests.has(requestKey)) {
    pendingRequests.get(requestKey).abort();
  }

  pendingRequests.set(requestKey, controller);

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
});

apiConfig.interceptors.response.use(
  response => {
    const requestKey = `${response.config.url}&${response.config.method}`;
    pendingRequests.delete(requestKey);
    return response;
  },
  error => {
    if (error.config) {
      const requestKey = `${error.config.url}&${error.config.method}`;
      pendingRequests.delete(requestKey);
    }
    return Promise.reject(error);
  }
);

const useAbortPendingRequests = () => {
  const location = useLocation();

  useEffect(() => {
    const abortPendingRequests = () => {
      pendingRequests.forEach((controller, key) => {
        controller.abort();
        pendingRequests.delete(key);
      });
    };

    abortPendingRequests();
  }, [location]);
};

export { apiConfig, useAbortPendingRequests };
