export const streamFetch = async (
  { url, method = "GET", token, body = null, signal },
  onData
) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    };

    const options = {
      method,
      headers,
      signal
    };

    if (method !== "GET" && body) {
      options.body = JSON.stringify(body);
    }

    const response = await fetch(url, options);

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let done = false;
    let buffer = "";

    while (!done) {
      const { value, done: readerDone } = await reader.read();
      done = readerDone;
      buffer += decoder.decode(value || new Uint8Array(), { stream: !done });
      const parts = buffer.split("\n\n");
      buffer = parts.pop();
      for (const part of parts) {
        if (part.startsWith("data: ")) {
          onData(part);
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const getQueryParams = paramsObject => {
  const params = new URLSearchParams();
  for (const [key, value] of Object.entries(paramsObject)) {
    if (value) {
      params.append(key, value);
    }
  }

  return params.toString();
};
