import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getQueryParamWithKey } from "../../utils/url";
import { streamFetch } from "../../utils/service";
import { API_URL } from "../../config";
import { useAuth } from "../../context/auth.context";
import { sourceTitle } from "../../constants/source";
import { Pagination } from "../../components";
import Markdown from "react-markdown";

const Analyze = () => {
  const s3Url = getQueryParamWithKey("s3Url");
  const { user } = useAuth();
  const [data, setData] = useState(null);
  const [analysis, setAnalysis] = useState("");
  const [similars, setSimilars] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;
  
  // Add pagination state
  const [page, setPage] = useState(1);
  const [paginationData, setPaginationData] = useState({
    current_page: 1,
    page_size: 10,
    total_pages: 1,
    total_results: 0,
    has_next: false,
    has_previous: false
  });

  // Add new state for loading more similar documents
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  
  // Add new state for analysis_id
  const [analysisId, setAnalysisId] = useState(null);

  // Add a new state to track analysis completion
  const [analysisComplete, setAnalysisComplete] = useState(false);

  // Ensure totalResults is derived from paginationData
  const totalResults = paginationData.total_results;

  // Initial load effect - for analysis and first page
  useEffect(() => {
    const init = async () => {
      try {
        setError(null);
        setIsLoading(true);
        setData(null);
        setAnalysis("");
        setSimilars([]);
        setPage(1); // Reset to page 1 on new analysis
        
        if (!s3Url) {
          throw new Error("No document URL provided");
        }

        const controller = new AbortController();

        await streamFetch(
          {
            url: `${API_URL}/analysis`,
            method: "POST",
            token: user.token,
            body: {
              s3_url_json: s3Url,
              recent_only: true
            },
            signal: controller.signal,
          },
          async (chunk) => {
            try {
              if (!chunk || !chunk.includes("data: ") || 
                  chunk === "data: [DONE]" || 
                  chunk === 'data: {"event": "DONE"}') {
                return;
              }
              setIsLoading(false);
              const chunkData = JSON.parse(chunk.split("data: ")[1]);

              if (chunkData.error) {
                setError({
                  message: "Analiz sırasında bir hata oluştu. Lütfen tekrar deneyin.",
                  details: chunkData.error,
                });
                controller.abort();
                return;
              }

              if (chunkData.original_document) {
                setData(chunkData.original_document);
              }

              // Handle pagination data
              if (chunkData.pagination) {
                setPaginationData(chunkData.pagination);
              }

              // Handle individual similar document
              if (chunkData.similar_document) {
                const newDoc = chunkData.similar_document;
                setSimilars(prev => {
                  // Check if document already exists
                  const existingIndex = prev.findIndex(doc => doc.document_id === newDoc.document_id);
                  if (existingIndex >= 0) {
                    // Update existing document
                    const updatedDocs = [...prev];
                    updatedDocs[existingIndex] = { ...updatedDocs[existingIndex], ...newDoc };
                    return updatedDocs;
                  } else {
                    // Add new document
                    return [...prev, newDoc];
                  }
                });
              }

              if (chunkData.analysis) {
                setAnalysis((prevState) => prevState + chunkData.analysis);
              }
              if (chunkData.status) {
                setStatus(chunkData.status);
              }
              if (chunkData.event === "DONE" || chunkData.status === "DONE") {
                setStatus("");
              }
              if (chunkData.analysis_id) {
                setAnalysisId(chunkData.analysis_id);
              }
              if (chunkData.event === "ANALYSIS_DONE") {
                setAnalysisComplete(true);
              }
            } catch (parseError) {
              console.error("Error parsing chunk:", parseError);
              setError({
                message: "Veri işlenirken bir hata oluştu.",
                details: parseError.message,
              });
            }
          }
        );

        setRetryCount(0);
      } catch (err) {
        console.error("Analysis error:", err);

        if (err.name === "AbortError") {
          if (retryCount < MAX_RETRIES) {
            setRetryCount((prev) => prev + 1);
            setError({
              message: "İşlem zaman aşımına uğradı. Otomatik olarak tekrar deneniyor...",
              details: `Deneme ${retryCount + 1}/${MAX_RETRIES}`,
            });
            setTimeout(() => {
              init();
            }, 2000);
            return;
          } else {
            setError({
              message: "Analiz zaman aşımına uğradı. Lütfen sayfayı yenileyip tekrar deneyin.",
              details: "Maksimum deneme sayısına ulaşıldı.",
            });
          }
        } else {
          setError({
            message: "Analiz sırasında bir hata oluştu. Lütfen tekrar deneyin.",
            details: err.message,
          });
        }
        setIsLoading(false);
      }
    };

    init();
  }, [s3Url, user.token]);

  // Function to fetch similar documents for a page
  const fetchSimilarDocumentsPage = async (pageNumber, pageSize) => {
    setIsLoadingMore(true);
    try {
      if (!analysisId) {
        throw new Error("No analysis ID available");
      }

      const response = await fetch(
        `${API_URL}/analysis/page?analysis_id=${analysisId}&page=${pageNumber}&page_size=${pageSize}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (result.error) {
        throw new Error(result.error);
      }

      // Update pagination data and similar documents
      setPaginationData(result.pagination);
      setSimilars(result.similar_documents.map(doc => ({
        ...doc,
        s3_url_json: doc.s3_url_json || '', // Ensure s3_url_json is defined
      })));
      
    } catch (err) {
      console.error("Error fetching similar documents:", err);
      setError({
        message: "Benzer kararlar yüklenirken bir hata oluştu.",
        details: err.message,
      });
    } finally {
      setIsLoadingMore(false);
    }
  };

  // Add this function to handle page changes
  const handlePageChange = async (newPage) => {
    try {
      if (!analysisId) {
        console.error("No analysis ID available");
        return;
      }

      setIsLoadingMore(true);
      setSimilars([]); // Clear existing similars before loading new page
      
      const controller = new AbortController();

      await streamFetch(
        {
          url: `${API_URL}/analysis/page?analysis_id=${analysisId}&page=${newPage}&page_size=${paginationData.page_size}`,
          method: "GET",
          token: user.token,
          signal: controller.signal,
        },
        async (chunk) => {
          try {
            if (!chunk || !chunk.includes("data: ") || 
                chunk === "data: [DONE]" || 
                chunk === 'data: {"event": "DONE"}') {
              return;
            }

            const chunkData = JSON.parse(chunk.split("data: ")[1]);

            if (chunkData.error) {
              setError({
                message: "Sayfa yüklenirken bir hata oluştu",
                details: chunkData.error,
              });
              controller.abort();
              return;
            }

            // Handle pagination data
            if (chunkData.pagination) {
              setPaginationData(chunkData.pagination);
              setPage(newPage); // Update current page
            }

            // Handle individual similar document
            if (chunkData.similar_document) {
              const newDoc = chunkData.similar_document;
              setSimilars(prev => {
                // Check if document already exists
                const existingIndex = prev.findIndex(doc => doc.document_id === newDoc.document_id);
                if (existingIndex >= 0) {
                  // Update existing document
                  const updatedDocs = [...prev];
                  updatedDocs[existingIndex] = { ...updatedDocs[existingIndex], ...newDoc };
                  return updatedDocs;
                } else {
                  // Add new document
                  return [...prev, newDoc];
                }
              });
            }

            if (chunkData.status) {
              setStatus(chunkData.status);
            }

            if (chunkData.event === "DONE" || chunkData.status === "DONE") {
              setStatus("");
              setIsLoadingMore(false);
            }
          } catch (parseError) {
            console.error("Error parsing chunk:", parseError);
            setError({
              message: "Veri işlenirken bir hata oluştu",
              details: parseError.message,
            });
          }
        }
      );

    } catch (error) {
      console.error("Error fetching page:", error);
      setError({
        message: "Sayfa yüklenirken bir hata oluştu",
        details: error.message,
      });
    } finally {
      setIsLoadingMore(false);
    }
  };

  if (error) {
    return (
      <div className="mx-auto flex w-full max-w-4xl flex-col justify-center">
        <div className="mb-4 rounded-md bg-red-50 p-4 dark:bg-red-900/50">
          <div className="text-center text-sm font-medium text-red-800 dark:text-red-200">
            {error.message}
            {error.details && (
              <div className="mt-2 text-xs text-red-700 dark:text-red-300">
                {error.details}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto flex w-full max-w-4xl flex-col justify-center">
      {isLoading && (
        <>
          <div className="mb-8 flex w-full animate-pulse">
            <div className="flex w-full flex-col">
              <div className="min-h-[280px] w-full rounded-lg bg-gray-100 shadow-md dark:bg-gray-700"></div>
            </div>
          </div>
        </>
      )}
      {data && (
        <>
          <div className="mb-3 font-display text-2xl font-medium text-primary-500">
            Ana Karar
          </div>
          <div className="mb-2 font-display text-lg font-semibold text-gray-800 dark:text-white">
            {data.title || `${sourceTitle[data.source]} ${data.daire} Kararı`}
          </div>
          <div className="flex gap-3 text-sm text-gray-600 md:flex-col md:gap-1 dark:text-gray-400">
            <div className={"flex gap-1"}>
              <strong className="font-medium">{sourceTitle[data.source]} {data.daire}</strong>
            </div>
            <div className="flex gap-1">
              <span>Esas No:</span>
              <strong className="font-medium">{data.esasNo}</strong>
            </div>
            <div className="flex gap-1">
              <span>Karar No:</span>
              <strong className="font-medium">{data.kararNo}</strong>
            </div>
            <div className="flex gap-1">
              <span>Karar Tarihi:</span>
              <strong className="font-medium">{data.kararTarihi}</strong>
            </div>
          </div>
          <article className="prose prose-base prose-gray mb-8 mt-2 dark:prose-invert">
            {data.summary}
          </article>
        </>
      )}
      {status && (
        <div className="mb-8 text-left text-base font-medium text-status-inProgress">
          {status}
        </div>
      )}
      {analysis && (
        <>
          <div className="mb-3 font-display text-2xl font-medium text-primary-500">
            Benzer Karar Analizi
          </div>
          <article className="prose prose-base prose-gray mb-8 dark:prose-invert">
            <Markdown>{analysis}</Markdown>
          </article>
        </>
      )}
      {analysisComplete && (
        <>
          <div className="mb-3 font-display text-2xl font-medium text-primary-500">
            Benzer Kararların Listesi
          </div>
          <div className="mb-3 text-sm text-gray-700 dark:text-gray-500">
            Toplam <strong>{paginationData.total_results}</strong> benzer karar bulundu
          </div>
          <section className="mb-4 flex flex-col gap-2">
            {similars.map((result, index) => (
              <div
                key={index}
                className="flex cursor-pointer flex-col rounded-2xl border border-gray-200 bg-white p-4 transition duration-300 ease-in-out hover:bg-gray-25 dark:border-gray-700 dark:bg-gray-900 dark:hover:bg-gray-950"
                onClick={() =>
                  result?.s3_url_json && navigate(`/doc/ictihat?s3Url=${encodeURIComponent(result.s3_url_json)}`)
                }
              >
                <div className="flex flex-col gap-2">
                  <div className="flex justify-between font-medium text-gray-800 dark:text-white">
                    <div>
                      {result.title ? (
                        result.title
                      ) : (
                        // Show loading indicator for title
                        <div className="h-6 w-3/4 animate-pulse rounded bg-gray-200 dark:bg-gray-700"></div>
                      )}
                    </div>
                    {result.durum && (
                      <div>{result.durum}</div>
                    )}
                  </div>
                  <div className="flex gap-3 text-sm text-gray-700 md:flex-col md:gap-1 dark:text-gray-500">
                    <div className={"flex gap-1"}>
                      <strong className="font-medium">
                        {sourceTitle[result?.source]} {result?.daire}
                      </strong>
                    </div>
                    <div className="flex gap-1">
                      <span>Esas No:</span>
                      <strong className="font-medium">{result?.esasNo || '-'}</strong>
                    </div>
                    <div className="flex gap-1">
                      <span>Karar No:</span>
                      <strong className="font-medium">{result?.kararNo || '-'}</strong>
                    </div>
                    <div className="flex gap-1">
                      <span>Karar Tarihi:</span>
                      <strong className="font-medium">
                        {result?.kararTarihi || '-'}
                      </strong>
                    </div>
                  </div>
                  <div className="text-sm text-gray-600 dark:text-gray-300">
                    {result.summary ? (
                      result.summary
                    ) : (
                      // Show loading indicator for summary
                      <div className="space-y-2">
                        <div className="h-4 w-full animate-pulse rounded bg-gray-200 dark:bg-gray-700"></div>
                        <div className="h-4 w-3/4 animate-pulse rounded bg-gray-200 dark:bg-gray-700"></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {isLoading && similars.length === 0 && (
              // Show loading placeholders when data is still loading and no similar documents have been loaded
              <div className="animate-pulse space-y-4">
                {[...Array(paginationData.page_size)].map((_, i) => (
                  <div
                    key={i}
                    className="rounded-2xl border border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-900"
                  >
                    <div className="space-y-3">
                      <div className="h-6 w-3/4 rounded bg-gray-200 dark:bg-gray-700"></div>
                      <div className="space-y-2">
                        <div className="h-4 w-full rounded bg-gray-200 dark:bg-gray-700"></div>
                        <div className="h-4 w-3/4 rounded bg-gray-200 dark:bg-gray-700"></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </section>
          {paginationData.total_pages > 1 && (
            <Pagination
              totalPages={paginationData.total_pages}
              currentPage={page}
              pageSize={paginationData.page_size}
              setCurrentPage={handlePageChange}
              totalResults={totalResults}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Analyze;
