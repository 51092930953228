export const promptingApi = api => ({
    getRelatedQueries({ query }) {
      return api.post("/prompting/related-queries", {
        query
      });
    },
    randomQueries() {
      return api.get("/prompting/sample-queries");
    }
});
