import React, { useEffect, useState } from "react";
import {
  Button,
  DateInput,
  Input,
  MaskInput,
  Pagination,
  Select
} from "../../components";
import { useForm } from "react-hook-form";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { useScreenDetector } from "../../hooks/use-screen-detector";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";
import { convertDateFormat } from "../../utils/date";
import { sourceTitle } from "../../constants/source";
import { ICTIHAT_UNITS } from "../../constants/search-filters";
import { noValueFormatter } from "../../utils/formatter";

const institutions = [
  {
    label: "Yargıtay",
    value: "yargitay"
  },
  {
    label: "Danıştay",
    value: "danistay"
  },
  {
    label: "Bölge Adliye Mahkemesi",
    value: "uyap"
  },
  {
    label: "Hukuk Mahkemesi",
    value: "uyap"
  },
  {
    label: "Askeri Yüksek İdare Mahkemesi",
    value: "danistay"
  }
];

const SearchJurisprudence = () => {
  const [showDetailSearch, setShowDetailSearch] = useState(true);
  const [units, setUnits] = useState(ICTIHAT_UNITS);
  const [results, setResults] = useState([]);
  const { isMobile } = useScreenDetector();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({});
  const [firstOpen, setFirstOpen] = useState(true);
  const [paginationData, setPaginationData] = useState({
    currentPage: page,
    has_next: true,
    has_previous: false,
    page_size: 10,
    total_pages: 1,
    total_results: 0,
    accessible_results: 0,
    is_truncated: false
  });

  const {
    register,
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    watch,
    reset
  } = useForm();

  useEffect(() => {
    if (watch("index")) {
      const indexes = watch("index").map(item => item.value);
      setValue("daire", []);
      setUnits(
        ICTIHAT_UNITS.filter(unit => indexes.includes(unit.institution))
      );
    } else {
      setUnits(ICTIHAT_UNITS);
    }
  }, [watch("index")]);

  const onReset = () => {
    reset({
      query: "",
      index: [],
      daire: [],
      esas_no: "",
      karar_no: "",
      karar_tarih_start: "",
      karar_tarih_end: ""
    });
  };

  const onSubmit = async params => {
    const prepareParams = {
      ...params,
      index: params.index?.map(item => item.value) || [],
      daire: params.daire?.map(item => item.label) || [],
      karar_tarih_start: convertDateFormat(params.karar_tarih_start?.startDate),
      karar_tarih_end: convertDateFormat(params.karar_tarih_end?.startDate),
      esas_no: noValueFormatter(params.esas_no),
      karar_no: noValueFormatter(params.karar_no)
    };

    delete prepareParams.page;

    const { data } = await api.search.searchIctihat({
      ...prepareParams,
      page: 1
    });

    setResults(data.results);
    setPaginationData({
      currentPage: data.pagination.current_page,
      has_next: data.pagination.has_next,
      has_previous: data.pagination.has_previous,
      page_size: data.pagination.page_size,
      total_pages: data.pagination.total_pages,
      total_results: data.pagination.total_results,
      accessible_results: data.accessible_results,
      is_truncated: data.pagination.is_truncated
    });
    setParams(prepareParams);
    setPage(1);
    setFirstOpen(false);
  };

  useEffect(() => {
    if (!firstOpen) {
      const init = async () => {
        const { data } = await api.search.searchIctihat({
          ...params,
          page
        });
        setResults(data.results);
        setPaginationData({
          currentPage: data.pagination.current_page,
          has_next: data.pagination.has_next,
          has_previous: data.pagination.has_previous,
          page_size: data.pagination.page_size,
          total_pages: data.pagination.total_pages,
          total_results: data.pagination.total_results,
          accessible_results: data.accessible_results,
          is_truncated: data.pagination.is_truncated
        });
      };

      init();
    }
  }, [page]);

  const totalResults = paginationData.total_results;

  return (
    <div className="mx-auto flex w-full max-w-4xl flex-col justify-center">
      <h1
        className={
          "mb-4  pb-2 font-display text-xl font-medium text-gray-900 dark:border-gray-700 dark:text-white"
        }
      >
        İçtihat Arama
      </h1>
      <section className={"flex min-w-full flex-col "}>
        <div className={"flex gap-4"}>
          <Input
            name="query"
            error={errors.query}
            rules={{}}
            register={register}
            className="mb-4"
            placeholder="Aranacak kelimeyi girin"
            onKeyDown={e => {
              if (e.key === "Enter") {
                handleSubmit(onSubmit)();
              }
            }}
          />
        </div>
        {showDetailSearch && (
          <>
            <div className={"flex gap-4 md:flex-col md:gap-0"}>
              <Select
                options={institutions}
                name="index"
                error={errors.index}
                rules={{}}
                isMulti={true}
                control={control}
                placeholder="Kurum"
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    handleSubmit(onSubmit)();
                  }
                }}
              />
              <Select
                options={units}
                name="daire"
                error={errors.daire}
                rules={{}}
                isMulti={true}
                control={control}
                placeholder="Birim"
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    handleSubmit(onSubmit)();
                  }
                }}
              />
            </div>
            <div className={"flex gap-4 md:flex-col md:gap-0"}>
              <MaskInput
                mask="9999/99999"
                maskPlaceholder="1234/12345"
                name="esas_no"
                // @ts-ignore
                error={errors.esas_no}
                register={register}
                placeholder="Esas No"
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    handleSubmit(onSubmit)();
                  }
                }}
              />
              <MaskInput
                mask="9999/99999"
                maskPlaceholder="1234/12345"
                name="karar_no"
                // @ts-ignore
                error={errors.karar_no}
                register={register}
                placeholder="Karar No"
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    handleSubmit(onSubmit)();
                  }
                }}
              />
            </div>
            <div className={"flex gap-4 md:flex-col md:gap-0"}>
              <DateInput
                name="karar_tarih_start"
                // @ts-ignore
                error={errors.karar_tarih_start}
                control={control}
                placeholder="Karar Tarihi (Başlangıç)"
                maxDate={new Date()}
                rules={{}}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    handleSubmit(onSubmit)();
                  }
                }}
              />
              <DateInput
                name="karar_tarih_end"
                // @ts-ignore
                error={errors.karar_tarih_end}
                control={control}
                placeholder="Karar Tarihi (Bitiş)"
                maxDate={new Date()}
                rules={{}}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    handleSubmit(onSubmit)();
                  }
                }}
              />
            </div>
          </>
        )}
        <div className={"flex items-center justify-end gap-4 md:flex-col"}>
          <button
            className="flex items-center gap-1 text-sm font-medium text-primary-600 transition-colors duration-200 ease-in-out hover:text-primary-700 dark:text-primary-500 dark:hover:text-primary-400"
            onClick={() => setShowDetailSearch(!showDetailSearch)}
          >
            Detaylı aramayı {showDetailSearch ? "kapat" : "göster"}
            {!showDetailSearch ? (
              <IconChevronDown size={20} />
            ) : (
              <IconChevronUp size={20} />
            )}
          </button>
          <div className={"flex justify-end gap-4 md:w-full"}>
            <Button
              onClick={() => onReset()}
              isFullWidth={isMobile}
              variant="secondary"
            >
              Sıfırla
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              isFullWidth={isMobile}
            >
              Ara
            </Button>
          </div>
        </div>
      </section>
      <section className="my-10 flex flex-col gap-2">
        {results.length > 0 && (
          <div className="mb-3 text-sm text-gray-700 dark:text-gray-500">
          Toplam{" "}
          <strong>
            {paginationData.total_results.toLocaleString('tr-TR')}
          </strong>{" "}
          sonuç bulundu
        </div>
        )}
        {results.map((result, index) => (
          <div
            key={index}
            className={
              "flex cursor-pointer flex-col rounded-2xl border border-gray-200 bg-white p-4 transition duration-300 ease-in-out hover:bg-gray-25 dark:border-gray-700 dark:bg-gray-900 dark:hover:bg-gray-950"
            }
            onClick={() => navigate(`/doc/ictihat?s3Url=${result.s3_url_json}`)}
          >
            <div className={"flex flex-col gap-2"}>
              <div className="font-medium text-gray-800 dark:text-white">
                {sourceTitle[result.source]} {result.daire} Kararı
              </div>
              <div className="flex gap-3 text-sm text-gray-700 md:flex-col md:gap-1 dark:text-gray-500">
                <div className="flex gap-1">
                  <span>Esas No:</span>
                  <strong className="font-medium">{result.esasNo}</strong>
                </div>
                <div className="flex gap-1">
                  <span>Karar No:</span>
                  <strong className="font-medium">{result.kararNo}</strong>
                </div>
                <div className="flex gap-1">
                  <span>Karar Tarihi:</span>
                  <strong className="font-medium">{result.kararTarihi}</strong>
                </div>
              </div>
              <div
                className={"text-sm text-gray-600 dark:text-gray-300"}
                dangerouslySetInnerHTML={{
                  __html: result.highlights.content?.join(" ")
                }}
              />
            </div>
          </div>
        ))}
        {results.length > 0 && (
          <Pagination
            totalPages={paginationData.total_pages}
            currentPage={page}
            pageSize={paginationData.page_size}
            setCurrentPage={pageValue => setPage(pageValue)}
            totalResults={paginationData.total_results}
            accessibleResults={paginationData.accessible_results}
            isTruncated={paginationData.is_truncated}
          />
        )}
        {!firstOpen && results.length === 0 && (
          <div className="mt-4 flex flex-col items-center justify-center gap-1 text-gray-800 dark:text-white">
            <div className="text-lg font-semibold">Sonuç bulunamadı.</div>
            <div className="text-base text-gray-600 dark:text-gray-200">
              Arama yaptığınız kriterlere göre herhangi bir sonuç bulunamadı.
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default SearchJurisprudence;
