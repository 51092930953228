import React from "react"; // Add this line
import { useRoutes } from "react-router-dom";
import routes from "./pages";
import "./assets/style/index.scss";
import { useEffect } from "react";
import { isDarkTheme } from "./utils/theme";
import api from "./services/api";
import { useAuth } from "./context/auth.context";
import { Analytics } from "@vercel/analytics/react";
import { useAbortPendingRequests } from "./config";

function App() {
  const { user, setUser } = useAuth();
  useAbortPendingRequests();

  useEffect(() => {
    const init = async () => {
      if (user && user.token) {
        await api.user
          .getProfile()
          .then(res => {
            const userData = res.data;
            userData.token = user.token;
            setUser(userData);
          })
          .catch(err => {
            if (err.code === "ERR_CANCELED") {
              init();
              return;
            }
            setUser({});
            localStorage.removeItem("user");
            window.location.reload();
          });
      }
    };

    if (isDarkTheme()) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    init();
  }, []);

  return (
    <>
      <Analytics />
      {useRoutes(routes)}
    </>
  );
}

export default App;
