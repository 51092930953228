import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconClock } from "@tabler/icons-react";
import { timeAgo } from "../../utils/date";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";

const History = () => {
  const navigate = useNavigate();
  const [threads, setThreads] = useState([]);

  useEffect(() => {
    const init = async () => {
      api.chat
        .getThreadList()
        .then(res => {
          setThreads(res.data);
        })
        .catch(err => console.error(err));
    };

    init();
  }, []);

  const historyList = useMemo(() => {
    return threads.sort(
      (a, b) => new Date(b.last_updated) - new Date(a.last_updated)
    );
  }, [threads]);

  const { t } = useTranslation();
  return (
    <div className="mx-auto flex w-full max-w-4xl flex-col justify-center">
      <h1
        className={
          "mb-4 pb-2 font-display text-xl font-medium text-gray-900 dark:border-gray-700 dark:text-white"
        }
      >
        {t("_history.title")}
      </h1>
      <div className="mx-auto w-full max-w-4xl">
        <section className={"flex min-w-full flex-col"}>
          {historyList.map((item, i) => (
            <div
              onClick={() => navigate(`/chat/${item.thread_id}`)}
              className={`cursor-pointer py-3 ${i === 0 ? "" : "border-t border-gray-100 dark:border-gray-800"}`}
              key={item.thread_id}
            >
              <div className="mb-1 text-base font-medium text-gray-800 dark:text-white">
                {item.thread_title}
              </div>
              <div>
                <span className="mt-2 flex items-center gap-1 text-xs text-gray-600">
                  <IconClock size={13} />
                  <span>{timeAgo(item.last_updated)}</span>
                </span>
              </div>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export default History;
