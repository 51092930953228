import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import { getQueryParamWithKey } from "../../utils/url";
import DOMPurify from "dompurify";
import { IconBookmark, IconBookmarkFilled } from "@tabler/icons-react";
import { convertToHTML, removeAllStylesFromText } from "../../utils/html";
import Markdown from "react-markdown";

const DocDetail = () => {
  const { type } = useParams();
  const s3Url = getQueryParamWithKey("s3Url");
  const [content, setContent] = useState(null);
  const [isLoading, setIsLoading] = useState({
    summary: true,
    content: true,
    bookmark: true
  });
  const [params, setParams] = useState({
    documentId: null,
    documentSource: null
  });
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [summary, setSummary] = useState(null);
  const [title, setTitle] = useState(
    type === "mevzuat" ? "Mevzuat" : "İçtihat"
  );
  const navigate = useNavigate();

  const addBookmark = async () => {
    await api.chat
      .addFavorite({
        documentId: params.documentId,
        documentSource: params.documentSource
      })
      .then(() => {
        setIsBookmarked(true);
      })
      .catch(err => console.error(err));
  };

  const removeBookmark = async () => {
    await api.chat
      .removeFavorite({
        documentId: params.documentId,
        documentSource: params.documentSource
      })
      .then(() => {
        setIsBookmarked(false);
      })
      .catch(err => console.error(err));
  };

  useEffect(() => {
    const init = async () => {
      let documentId = "";
      let documentSource = "";
      if (type === "mevzuat") {
        const response = await api.content
          .getMevzuatContent(s3Url)
          .catch(err => console.error(err));

        if (response) {
          const getData = response.data;
          documentId = getData.id;
          documentSource = getData.source;
          const cleanHTML = DOMPurify.sanitize(getData.content);
          setContent(cleanHTML);
          setIsLoading(prevState => ({
            ...prevState,
            content: false
          }));
        }
      } else {
        const response = await api.content
          .getIctihatContent(s3Url)
          .catch(err => console.error(err));

        const { kararMetni, daire, kararTarihi, esasNo, kararNo, id, source } =
          response.data;
        documentId = id;
        documentSource = source;
        const htmlContent = `
          <html>
            <head>
              <title>İçtihat: ${daire} - ${kararTarihi}</title>
            </head>
            <body class="no-scrollbar">
              <div class="meta prose prose-base prose-gray dark:prose-invert no-scrollbar">
                <p><strong>Daire:</strong> ${daire}</p>
                <p><strong>Karar Tarihi:</strong> ${kararTarihi}</p>
                <p><strong>Esas No:</strong> ${esasNo}</p>
                <p><strong>Karar No:</strong> ${kararNo}</p>
              </div>
              <div class="prose prose-base prose-gray overflow-scroll leading-6 dark:prose-invert prose-h1:text-xl prose-h2:text-lg prose-p:mb-0 prose-p:mt-1.5 prose-ol:mt-0 prose-ol:flex prose-ol:flex-col prose-table:text-[length:inherit] mt-4">${convertToHTML(kararMetni)}</div>
            </body>
          </html>`;
        const cleanHTML = DOMPurify.sanitize(htmlContent);
        setContent(cleanHTML);
        setIsLoading(prevState => ({
          ...prevState,
          content: false
        }));
      }

      const response = await api.chat
        .getFavorites()
        .catch(err => console.error(err));

      const { favorites } = response.data;
      const isBookmarkedStatus = favorites.some(
        favorite =>
          favorite.document_id === documentId &&
          favorite.document_source === documentSource
      );
      setParams({ documentId, documentSource });
      setIsBookmarked(isBookmarkedStatus);
      setIsLoading(prevState => ({
        ...prevState,
        bookmark: false
      }));

      const { data } =
        await api.doc[
          type === "mevzuat" ? "mevzuatSummarize" : "ictihatSummarize"
        ](s3Url);
      if (data.summary) {
        setSummary(data.summary);
        if (data.title) {
          setTitle(data.title);
        }
        setIsLoading(prevState => ({
          ...prevState,
          summary: false
        }));
      }

      return () => {
        setContent(null);
        setIsLoading({
          content: true,
          summary: true,
          bookmark: true
        });
        setParams({ documentId: null, documentSource: null });
        setIsBookmarked(false);
      };
    };

    init();
  }, []);

  const { t } = useTranslation();
  return (
    <div className="mx-auto flex w-full max-w-4xl flex-col justify-center">
      <div className="mb-6 flex items-center justify-between text-gray-700 dark:text-white">
        <h1
          className={"mb-4 font-display text-2xl font-medium text-primary-500"}
        >
          {title}
        </h1>
        <button
          title={isBookmarked ? "Kaydetme" : "Kaydet"}
          onClick={isBookmarked ? removeBookmark : addBookmark}
          className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full transition duration-300 ease-in-out hover:bg-gray-50 hover:dark:bg-gray-800 "
        >
          {!isLoading.bookmark &&
            (isBookmarked ? <IconBookmarkFilled /> : <IconBookmark />)}
        </button>
      </div>

      <div className="mx-auto w-full max-w-4xl">
        <section className={"flex min-w-full flex-col"}>
          <div className="mb-2 flex items-center justify-between text-gray-700 dark:text-white">
            <h1 className={"font-display text-xl font-semibold"}>
              {type === "mevzuat" ? "Mevzuat" : "İçtihat"} Özeti
            </h1>
          </div>
          {isLoading.summary ? (
            <>
              <div className="mb-8 text-left text-base font-medium text-status-inProgress">
                Size kolaylık sağlaması için belgenin özeti oluşturuluyor. Bu
                işlem bir kaç dakika sürebilir, lütfen bekleyiniz...{" "}
              </div>
              <div className="mb-8 flex w-full animate-pulse">
                <div className="flex w-full flex-col">
                  <div className="min-h-[280px] w-full rounded-lg bg-gray-100 shadow-md dark:bg-gray-700"></div>
                </div>
              </div>
            </>
          ) : (
            <article className="prose prose-base prose-gray mb-8 dark:prose-invert">
              <Markdown>{summary}</Markdown>
            </article>
          )}
          {type === "ictihat" && !isLoading.content && (
            <div className="flex items-center justify-start mb-8">
              <button
                onClick={() => navigate(`/analyze?s3Url=${s3Url}`)}
                className="min-w-[250px] max-w-[350px] rounded-full border border-primary-500 text-primary-500 px-4 py-2 transition-all hover:bg-primary-500 hover:text-white"
              >
                Güncel karar analizi
              </button>
              <div className="ml-4 text-sm text-primary-500">
                Yapay zeka ile bu kararın daha güncellerini arayabilir, benzer kararlar arasında bir değerlendirme yapabilirsiniz.
              </div>
            </div>
          )}
          <div className="mb-2 flex items-center justify-between text-gray-700 dark:text-white">
            <h1 className={"font-display text-xl font-semibold"}>
              {type === "mevzuat" ? "Mevzuat" : "İçtihat"} Detayı
            </h1>
          </div>
          {isLoading.content ? (
            <>
              <div className="mb-2 text-base font-medium text-gray-600 dark:text-gray-200">
                Belge içeriği alınıyor… Lütfen bekleyiniz.
              </div>
              <div className="flex w-full animate-pulse">
                <div className="flex w-full flex-col">
                  <div className="min-h-[440px] w-full rounded-lg bg-gray-100 shadow-md dark:bg-gray-700"></div>
                </div>
              </div>
            </>
          ) : (
            <article
              className="no-scrollbar prose prose-base prose-gray mb-10 overflow-scroll leading-6 dark:prose-invert prose-h1:text-xl prose-h2:text-lg prose-p:mb-0 prose-p:mt-1.5 prose-ol:mt-0 prose-ol:flex prose-ol:flex-col prose-table:text-[length:inherit] md:mt-6 md:px-4 md:py-8"
              dangerouslySetInnerHTML={{
                __html: removeAllStylesFromText(content)
              }}
            />
          )}
        </section>
      </div>
    </div>
  );
};

export default DocDetail;
